$navigation-background-colour: #e9ecef;

.navigation {
  display: flex;
  justify-content: center;
  background-color: $navigation-background-colour;

  &__list {
    margin: 0 auto;
    padding: 0;
    max-width: 960px;
    list-style: none;
    display: flex;
    justify-content: center;
    
    &__item {
      display: flex;
      
      &__link {
        padding: 1rem 2rem;
        color: #212529;
        text-decoration: none;
        
        &:hover, &.active {
          background-color: darken($navigation-background-colour, 30%);
          color: #f8f9fa;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
