.project {
  
  &__image-container {
    margin-top: 2rem;
  }

  &__title {
    margin-top: 1rem;
  }
  
  &__date {
    display: block;
    font-style: italic;
    margin-bottom: 1rem;
  }

	&__app-store-download-link, &__links {
		margin-top: 2rem;
		text-align: center;
	
		&__separator {
			margin: 0 1rem;
		}
	}
	
	&__links {
		margin-bottom: 2rem;
	}
  
}