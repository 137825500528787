html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr auto;
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #f8f9fa;
  color: #212529;
}

main {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 1.45rem;
  margin-left: 0;
  padding: 0;
  font-weight: bold;
  line-height: 1.1;
}

$mediaQueryMultiplier: 0.8;

h1 {
  font-size: 2.25rem * $mediaQueryMultiplier;
}

h2 {
  font-size: 1.62671rem * $mediaQueryMultiplier;
}

h3 {
  font-size: 1.38316rem * $mediaQueryMultiplier;
}

h4 {
  font-size: 1rem * $mediaQueryMultiplier;
  margin-bottom: 1rem;
}

h5 {
  font-size: 0.85028rem * $mediaQueryMultiplier;
}

h6 {
  font-size: 0.78405rem * $mediaQueryMultiplier;
}

p {
  line-height: 1.5rem;
}

a {
  color: #EF2B83;
}

@media (min-width: 768px) {
  main {
    margin-top: 2rem;
  }
  
  h1 {
    font-size: 2.25rem;
  }
  
  h2 {
    font-size: 1.62671rem;
  }
  
  h3 {
    font-size: 1.38316rem;
  }
  
  h4 {
    font-size: 1rem;
  }
  
  h5 {
    font-size: 0.85028rem;
  }
  
  h6 {
    font-size: 0.78405rem;
  }
}
