.outplay {
    &__press-kit {
        &__images {
            display: flex;
            flex-flow: row wrap;
            gap: 50px;
            justify-content: center;

            &__image {
                min-width: 0;

                &--large {
                    flex-basis: 40%;
                }
                
                &--small {
                    flex-basis: 20%;
                }
            }
        }
    }
}