.footer {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  
  &__inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  &__copyright {
    margin-bottom: 0.5rem;
  }
  
  &__links {
    text-align: center;
    
    &__separator {
      margin: 0 1rem;
    }
  }
}

@media (min-width: 768px) {
  .footer {
    &__inner-container {
      flex-direction: row;
      justify-content: center;
    }
    
    &__copyright {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }
}
