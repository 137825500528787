.header {
  display: flex;
  justify-content: center;
  background: linear-gradient(
    to right, 
    #B5D4FC 5%,
    #007ABD 15%, 
    #0896D1 25%, 
    #FDA513 35%, 
    #FDBA0C 45%, 
    #FA4942 55%, 
    #F9263B 65%, 
    #EF2B83 75%, 
    #B7288D 85%, 
    #68438D 95%);
  background-size: 400%;
  background-position: 0%;
  padding: 3.5rem 0;

  -webkit-animation: rainbow 50s ease infinite;
  -z-animation: rainbow 50s ease infinite;
  -o-animation: rainbow 50s ease infinite;
    animation: rainbow 50s ease infinite;

  &__title {
    margin: 0;
    font-size: 2rem;
    text-align: center;
  
    &__link {
      text-decoration: none;
      color: #f8f9fa;
    }
  }

  &__sub-title {
    margin: 1rem 0 0 0;
    color: #f8f9fa;
    font-size: 1.1rem;
    text-align: center;
    font-weight: 600;
  }
}

@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}

@media (min-width: 768px) {
  .header {
    &__title {
      font-size: 3rem;
    }

    &__sub-title {
      font-size: 1.3rem;
    }
  }
}
