.projects {
  
  &__separator {
    margin: 2rem;
    border: 1px solid #e9ecef;
  }
  
  &__list {
    padding: 0;
    
    &__item {
      margin: 1rem 0;
      list-style-type: none;
    }
  }
  
}
